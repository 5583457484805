/* eslint-disable no-restricted-syntax, no-param-reassign */
import React, { useEffect, useState } from 'react';
import { Button, Spinner } from '@iq/react-components';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { setActiveComponentId } from '../../bundles/application';
import { getActiveSite } from '../../bundles/sites';
import {
  getProject,
  downloadMimsConfig,
  downloadEcConfig,
  fetchProjectAction,
} from '../../bundles/projects';
import Sidebar from '../Sidebar';
import PageHeader from '../PageHeader';
import HeaderSettings from '../PageHeader/HeaderSettings';
import Notifications from '../Notifications';
import FoldableTreeView from './TreeView';
import { deepMerge, isEnergyConnectMimsProject } from '../../utils';

const BaseViewOverview = ({ isTenantAdminView = false, noSiteView = false, className = '' }) => {
  const dispatch = useDispatch();
  const site = useSelector(getActiveSite);
  const params = useParams();
  const { projectId } = params;

  const project = useSelector((state) => getProject(state, projectId));

  useEffect(() => {
    if (project) return;

    dispatch(fetchProjectAction(projectId));
  }, [dispatch, projectId, project]);

  const onResetComponent = () => dispatch(setActiveComponentId(null));

  const [isLoading, setIsLoading] = useState(false);

  const handleClickDownloadMims = () => {
    setIsLoading(true);
    setTimeout(() => {
      dispatch(downloadMimsConfig(project));
      setIsLoading(false);
    }, 2000);
  };

  const handleClickDownloadEc = () => {
    setIsLoading(true);
    setTimeout(() => {
      dispatch(downloadEcConfig(project));
      setIsLoading(false);
    }, 2000);
  };

  const [machExpandedIds, setMachExpandedIds] = useState([]);
  const [mimsExpandedIds, setMimsExpandedIds] = useState([]);
  const [ecExpandedIds, setEcExpandedIds] = useState([]);

  if (!project) return null;

  const convertMachComputers = (computers) => {
    const convertLinkIds = (id) => {
      let parent;
      const output = [];

      id.split('.').forEach((item) => {
        parent = parent ? `${parent}.${item}` : item;
        output.push(parent);
      });

      return output;
    };

    return computers.map((c) => ({
      name: c.name,
      id: c.name,
      children: c.groups.map((g) => ({
        name: g.name,
        id: `${c.name}.${g.name}`,
        children: g.groupItems.map((gi) => ({
          name: gi.name,
          id: `${c.name}.${g.name}.${gi.name}`,
          metadata: {
            expandMach: [1, c.name, `${c.name}.${g.name}`, `${c.name}.${g.name}.${gi.name}`],
            expandMims: [1].concat(
              convertLinkIds(`${gi.mimsEquipmentStructure}.${gi.mimsEquipmentPropertyName}`)
            ),
            expandEc: [1].concat(convertLinkIds(`${gi.ecItemStructure}.${gi.ecItemVariableName}`)),
          },
        })),
      })),
    }));
  };

  const expand = (str, value) => {
    const items = str.split('.');
    const output = {};
    let ref = output;

    items.slice(0, -1).forEach((item) => {
      ref[item] = {};
      ref = ref[item];
    });

    ref[items[items.length - 1]] = value;

    return output;
  };

  const convertToTreeFormat = (item, parentId) => {
    return Object.entries(item).map(([key, value]) => {
      let id;
      let children;
      if (value instanceof Object) {
        id = value.id;
        delete value.id;
        children = convertToTreeFormat(value, id);
      } else {
        id = `${parentId}.${key}`;
        children = [{ name: value }];
      }

      return {
        name: key,
        id,
        children,
      };
    });
  };

  const convertMimsEquipment = (equipments) => {
    const propertyReducer = (obj, item) => ({
      ...obj,
      [item.name]: item.machDataType,
    });

    const result = equipments
      .map((e) => {
        const properties = e.properties.reduce(propertyReducer, {});
        return expand(e.structure, { ...properties, id: e.structure });
      })
      .reverse()
      .reduce((accumulator, item) => deepMerge(accumulator, item), {});

    return convertToTreeFormat(result);
  };

  const convertedComputers = convertMachComputers(project.machComputers);
  const machModel = {
    name: '',
    children: [
      {
        name: project.name,
        children: convertedComputers,
      },
    ],
  };

  const convertedEquipments = convertMimsEquipment(project.mimsEquipment);
  const mimsModel = {
    name: '',
    children: [
      {
        name: `${project.name} (${project.ip})`,
        children: convertedEquipments,
      },
    ],
  };

  const projectIsEc = isEnergyConnectMimsProject(project);
  let ecModel;
  if (projectIsEc) {
    const ecList = [];
    project.ecModel.ecItems.forEach((item) => {
      const name = `${item.name} (${item.itemDesignation})`;

      const varList = [];
      const propList = [];
      for (const [key, value] of Object.entries(item.properties)) {
        propList.push({
          name: key,
          children: [
            { name: `Description: ${value.description}` },
            { name: `Data Type: ${value.ecDataType}` },
          ],
        });
      }
      const properties = propList.length > 0 ? [{ name: 'Properties', children: propList }] : [];

      for (const [key, value] of Object.entries(item.variables)) {
        varList.push({
          name: key,
          id: `${item.structure}.${key}`,
          children: [
            { name: `Description: ${value.description}` },
            { name: `Data Type: ${value.ecDataType}` },
            { name: `Unit: ${value.unit}` },
          ],
        });
      }
      const variables = varList.length > 0 ? [{ name: 'Variables', children: varList }] : [];

      const obj = {
        name,
        id: item.structure,
        key: item.name,
        parent: item.parent,
        children: variables.concat(properties),
      };
      ecList.push(obj);
    });

    // Re-arrange to parent
    const itemList = [];
    ecList.forEach((item) => {
      if (!item.parent) {
        itemList.push(item);
      } else {
        // Level 1
        itemList.forEach((obj) => {
          if (item.parent === obj.key) {
            obj.children.push(item);
          } else {
            // Level 2
            obj.children.forEach((obj2) => {
              if (item.parent === obj2.key) {
                obj2.children.push(item);
              }
            });
          }
        });
      }
    });

    ecModel = {
      name: '',
      children: [
        {
          name: project.ecModel.environment,
          children: [{ name: `Tenant (${project.ecModel.tenantId})`, children: itemList }],
        },
      ],
    };
  }

  return (
    <>
      <Sidebar
        projectId={projectId}
        ecProject={project.ecModel}
      />
      <section className={`base-view ${className}`}>
        <PageHeader
          site={!isTenantAdminView && site}
          title={{
            type: 'string',
            value: project.name,
          }}
          singlePage={true}
          isTenantAdminView={isTenantAdminView}
          dispatchResetComponent={onResetComponent}
        >
          <Notifications />

          <HeaderSettings
            site={site}
            noSiteView={noSiteView}
          />
        </PageHeader>
        <div className="panel">
          <div className="panel-header">
            <span className="iot-class">Overview</span>
            <div className="panel-header-button">
              {isLoading && <Spinner />}
              <Button onClick={handleClickDownloadMims}>Download MIMS</Button>
              {projectIsEc && <Button onClick={handleClickDownloadEc}>Download EC</Button>}
            </div>
          </div>

          <div className="data-container">
            <div className="column-wrapper">
              <header className="title">MACH</header>
              <div className="column mach-data">
                <FoldableTreeView
                  data={machModel}
                  expandedIds={machExpandedIds}
                  setMachExpandedIds={setMachExpandedIds}
                  setMimsExpandedIds={setMimsExpandedIds}
                  setEcExpandedIds={setEcExpandedIds}
                />
              </div>
            </div>

            <div className="column-wrapper">
              <header className="title">MIMS</header>
              <div className="column mims-data">
                <FoldableTreeView
                  data={mimsModel}
                  expandedIds={mimsExpandedIds}
                />
              </div>
            </div>

            {projectIsEc && (
              <div className="column-wrapper">
                <header className="title">EC</header>
                <div className="column dec-data">
                  <FoldableTreeView
                    data={ecModel}
                    expandedIds={ecExpandedIds}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default BaseViewOverview;
