import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { setActiveComponentId } from '../../bundles/application';
import { fetchProjectAction, getProject, updateProject } from '../../bundles/projects';
import { getActiveSite } from '../../bundles/sites';

import Sidebar from '../Sidebar';
import PageHeader from '../PageHeader';

import HeaderSettings from '../PageHeader/HeaderSettings';
import Notifications from '../Notifications';
import ProjectCreateUpdateView from '../ProjectCreateUpdateView';
import { isEnergyConnectMimsProject } from '../../utils';

const BaseViewMach = ({ isTenantAdminView = false, noSiteView = false, className = '' }) => {
  const dispatch = useDispatch();
  const site = useSelector(getActiveSite);
  const params = useParams();
  const { projectId } = params;
  const project = useSelector((state) => getProject(state, projectId));

  useEffect(() => {
    if (project) return;

    dispatch(fetchProjectAction(projectId));
  }, [dispatch, projectId]);

  const onResetComponent = () => dispatch(setActiveComponentId(null));

  const handleUpdateProject = (updatedProject) => {
    dispatch(
      updateProject({
        id: project.id,
        ...updatedProject,
      })
    );
  };

  if (project) {
    return (
      <>
        <Sidebar
          projectId={projectId}
          ecProject={project?.ecModel}
        />
        <section className={`base-view ${className}`}>
          <PageHeader
            site={!isTenantAdminView && site}
            title={{
              type: 'string',
              value: project.name,
            }}
            singlePage={true}
            isTenantAdminView={isTenantAdminView}
            dispatchResetComponent={onResetComponent}
          >
            <Notifications />

            <HeaderSettings
              site={site}
              noSiteView={noSiteView}
            />
          </PageHeader>

          <div className="panel">
            <div className="panel-header">
              <span>MIMS Configuration</span>
              <div className="panel-header-button">
                <ProjectCreateUpdateView
                  title={'Update Project'}
                  onSave={handleUpdateProject}
                  showButtonDownload={false}
                  createUpdateButtonText="Upload new config"
                  project={project}
                />
              </div>
            </div>

            <dl className="mims-config">
              <span>
                <dt>ID</dt>
                <dd>{project.id}</dd>
              </span>

              <span>
                <dt>Name</dt>
                <dd>{project.name}</dd>
              </span>

              <span>
                <dt>MIMS IP</dt>
                <dd>{project.ip}</dd>
              </span>

              <span>
                <dt>Subscription Type</dt>
                <dd>{project.subscriptionType}</dd>
              </span>

              {project.subscriptionType === 'SAMPLE_INTERVAL' && (
                <span>
                  <dt>Sample Rate</dt>
                  <dd>{project.sampleRate}</dd>
                </span>
              )}

              {isEnergyConnectMimsProject(project) && (
                <span>
                  <dt>MIMS Module ID</dt>
                  <dd>{project.moduleId}</dd>
                </span>
              )}
            </dl>
          </div>
        </section>
      </>
    );
  }

  return null;
};

export default BaseViewMach;
