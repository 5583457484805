import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { setActiveComponentId } from '../../bundles/application';
import { getActiveSite } from '../../bundles/sites';
import { fetchProjectAction, getProject, updateProject } from '../../bundles/projects';
import Sidebar from '../Sidebar';
import PageHeader from '../PageHeader';
import HeaderSettings from '../PageHeader/HeaderSettings';
import Notifications from '../Notifications';
import ProjectCreateUpdateView from '../ProjectCreateUpdateView';

const BaseViewIoTCloud = ({ isTenantAdminView = false, noSiteView = false, className = '' }) => {
  const dispatch = useDispatch();
  const site = useSelector(getActiveSite);
  const params = useParams();
  const { projectId } = params;
  const project = useSelector((state) => getProject(state, projectId));

  useEffect(() => {
    if (project) return;

    dispatch(fetchProjectAction(projectId));
  }, [dispatch, projectId]);

  const onResetComponent = () => dispatch(setActiveComponentId(null));

  if (!project) return null;

  const handleUpdateProject = (updatedProject) => {
    dispatch(updateProject(updatedProject));
  };

  return (
    <>
      <Sidebar
        projectId={projectId}
        ecProject={true}
      />
      <section className={`base-view ${className}`}>
        <PageHeader
          site={!isTenantAdminView && site}
          title={{
            type: 'string',
            value: project.name,
          }}
          singlePage={true}
          isTenantAdminView={isTenantAdminView}
          dispatchResetComponent={onResetComponent}
        >
          <Notifications />

          <HeaderSettings
            site={site}
            noSiteView={noSiteView}
          />
        </PageHeader>
        <div className="panel">
          <div className="panel-header">
            <span>EC Configuration</span>
            <div className="panel-header-button">
              <ProjectCreateUpdateView
                title={'Update Project'}
                onSave={handleUpdateProject}
                showButtonDownload={false}
                createUpdateButtonText="Upload new config"
                project={project}
              />
            </div>
          </div>

          <dl className="mims-config">
            <span>
              <dt>EC Environment</dt>
              <dd>{project?.ecModel?.environment}</dd>
            </span>

            <span>
              <dt>EC Tenant ID</dt>
              <dd>{project?.ecModel?.tenantId}</dd>
            </span>

            <span>
              <dt>EC Project ID</dt>
              <dd>{project?.ecModel?.projectId}</dd>
            </span>

            <span>
              <dt>EC Edge ID</dt>
              <dd>{project?.ecModel?.edgeId}</dd>
            </span>

            <span>
              <dt>EC Device Twin API</dt>
              <dd>{project?.ecModel?.deviceTwinAPI}</dd>
            </span>

            <span>
              <dt>EC Data Access API</dt>
              <dd>{project?.ecModel?.dataAccessAPI}</dd>
            </span>

            <span>
              <dt>EC Type Def API</dt>
              <dd>{project?.ecModel?.typeDefAPI}</dd>
            </span>
          </dl>
        </div>
      </section>
    </>
  );
};

export default BaseViewIoTCloud;
